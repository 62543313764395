import './bootstrap';
import '../../vendor/masmerise/livewire-toaster/resources/js';


document.addEventListener('DOMContentLoaded', (event) => {
    const checkbox = document.getElementById('burger');
    const div = document.getElementById('navigation');

    checkbox.addEventListener('change', function () {
        if (this.checked) {
            div.classList.add('show');
        } else {
            div.classList.remove('show');
        }
    });
});


// Share list
const shareButton = document.getElementById('share-list-button');

if (shareButton) {
    shareButton.addEventListener('click', async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Bekijk mij',
                    text: 'Check out this cool WebShare API demo.',
                    url: window.location.href
                });
                console.log('Successfully shared');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {

            const shareBox = document.getElementById('share-box');
            shareBox.classList.add('show');


        }
    });
}


// Focus on input field by editing profile img
document.getElementById('edit-image-button').addEventListener('click', function () {
    document.getElementById('file-upload-field').click();
});
